import React, {useEffect, useState} from 'react';
import {collection, getDocs} from "firebase/firestore/lite";
import db from "../../firebase";
import {Link} from "react-router-dom";
import Button from '@mui/material/Button';

function MainPage(props) {
    const [allDates, setAllDates] = useState([]);

    useEffect(() => {
        const fetchDates = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "dates"));
                const dates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setAllDates(dates);
            } catch (error) {
                console.error("Error fetching dates:", error);
            }
        };

        fetchDates();
    }, []);
    return (
        <div>
            <div>
                <Button variant="contained">Hello world</Button>
                {allDates.map(date => (
                    <Link key={date.id} to={`/date/${date.id}`}>
                            <p>Date: {date.date}</p>
                            <p>Location: {date.location}</p>
                            <p>Man Amount: {date.manAmount}</p>
                            <p>Woman Amount: {date.womanAmount}</p>
                            <p>Time: {date.time}</p>
                            <p>Description: {date.description}</p>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default MainPage;