import React, {useEffect, useState} from "react";
import "./App.css";
import db from "./firebase";
import {collection, getDocs, addDoc, doc, updateDoc} from "firebase/firestore/lite";
import { Routes, Route } from 'react-router-dom';
import AdminPage from "./pages/AdminPage/AdminPage";
import MainPage from "./pages/MainPage/MainPage";
import DatePage from "./pages/DatePage/DatePage";

function App() {

  return (
      <div className="App">
          <Routes>
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/main" element={<MainPage />} />
              <Route path="/date/:dateId" element={<DatePage />} />
          </Routes>

      </div>
  );
}

export default App;
