import React, {useEffect, useState} from 'react';
import './Admin.css';
import {addDoc, collection, doc, getDocs, updateDoc, deleteDoc} from "firebase/firestore/lite";
import db from "../../firebase";
import {Container, Card, CardMedia, CardContent, Typography, Stack, TextField, Box, Grid} from "@mui/material";
import Button from "@mui/material/Button";

function AdminPage(props) {
    const [date, setDate] = useState('');
    const [location, setLocation] = useState('');
    const [manAmount, setManAmount] = useState('');
    const [womanAmount, setWomanAmount] = useState('');
    const [time, setTime] = useState('');
    const [description, setDescription] = useState('');

    const [allDates, setAllDates] = useState([]);

    const [editedFields, setEditedFields] = useState({});

    const handleEditChange = (index, field, value) => {
        setEditedFields(prevState => ({
            ...prevState,
            [index]: {
                ...prevState[index],
                [field]: value
            }
        }));
    };

    async function addDateToDb(date) {
        try {
            const docRef = await addDoc(collection(db, "dates"), date);
            console.log("Document written with ID: ", docRef.id);
        } catch (error) {
            console.error("Error adding document: ", error);
        }

        // Fetch all documents from the "dates" collection
        const querySnapshot = await getDocs(collection(db, "dates"));
        const dates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAllDates(dates);
        console.log('dates', dates);
        return dates; // Return all documents
    }

    const editDate = async (id, updatedDate) => {
        try {
            await updateDoc(doc(db, "dates", id), updatedDate);
            console.log("Document updated with ID: ", id);
        } catch (error) {
            console.error("Error updating document: ", error);
            throw error;
        }
    };
    const handleAddDate = async () => {
        const dateInfo = {
            date,
            location,
            manAmount,
            womanAmount,
            time,
            description
        };

        try {
            const newDateId = await addDateToDb(dateInfo);
            const updatedDates = [...allDates, { id: newDateId, ...dateInfo }];
            setAllDates(updatedDates);
        } catch (error) {
            console.error("Error adding date:", error);
        }
    };

    const deleteDate = async id => {
        try {
            await deleteDoc(doc(db, "dates", id));
            console.log("Document deleted with ID: ", id);
            const updatedDates = allDates.filter(date => date.id !== id);
            setAllDates(updatedDates);
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    };

    const handleEdit = async (id) => {
        console.log('id', id)
        try {
            await editDate(id, editedFields);
            const updatedDates = allDates.map(date => {
                if (date.id === id) {
                    return { ...date, ...editedFields };
                }
                return date;
            });
            setAllDates(updatedDates);
            setEditedFields({}); // Clear edited fields
        } catch (error) {
            console.error("Error editing date:", error);
        }
    };
    useEffect(() => {
        const fetchDates = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "dates"));
                const dates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setAllDates(dates);
            } catch (error) {
                console.error("Error fetching dates:", error);
            }
        };

        fetchDates();
    }, []);
    return (
        <Container>
            <Container maxWidth="sm">
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <Typography variant="h6">Add Date</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Date"
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            fullWidth
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Location"
                            type="text"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            fullWidth
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Man Amount"
                            type="text"
                            value={manAmount}
                            onChange={(e) => setManAmount(e.target.value)}
                            fullWidth
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Woman Amount"
                            type="text"
                            value={womanAmount}
                            onChange={(e) => setWomanAmount(e.target.value)}
                            fullWidth
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Time"
                            type="text"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                            fullWidth
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Description"
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            // size='small'
                            multiline
                            rows={null}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleAddDate}>
                            Add Date
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            <Stack direction="row"  flexWrap='wrap' justifyContent='space-between'>
                {allDates.map((date,index) => (
                    <Card key={date.id} sx={{ maxWidth: 345, marginBottom: '20px' }}>
                        {/*<CardMedia*/}
                        {/*    sx={{ height: 140 }}*/}
                        {/*    image="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"*/}
                        {/*    title="green iguana"*/}
                        {/*/>*/}
                        <CardContent>
                            <Stack direction="row" spacing={1}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Date:
                                </Typography>
                                <Typography gutterBottom variant="body2" component="p">
                                    {date.date}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Location:
                                </Typography>
                                <Typography gutterBottom variant="body2" component="p">
                                    {date.location}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Man Amount:
                                </Typography>
                                <Typography gutterBottom variant="body2" component="p">
                                    {date.manAmount}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Woman Amount:
                                </Typography>
                                <Typography gutterBottom variant="body2" component="p">
                                    {date.womanAmount}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Time:
                                </Typography>
                                <Typography gutterBottom variant="body2" component="p">
                                    {date.time}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Description:
                                </Typography>
                                <Typography variant="body2" component="div" sx={{ overflowX: 'auto' }}>
                                    {/*{date.description}*/}
                                    dfnklstihkjfuhgrethwriut4wu9ibsfjsajfbueg'iqhei48yt94wfjdbgdfnv mndbgjkds;fbgusj;gdfnjbsghkr;ushuwionsadjkfbjbfueg;oinsjkdbfkbjkdhfg.bfjkbglsgr
                                </Typography>
                            </Stack>
                        </CardContent>
                        <Stack spacing={2} p={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Date:
                                </Typography>
                                    <TextField
                                        type="date"
                                        value={editedFields[index]?.date || date.date}
                                        onChange={(e) => handleEditChange(index, 'date', e.target.value)}
                                        size="small"
                                        style = {{width: '100%'}}
                                    />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Location:
                                </Typography>
                                <TextField
                                    type="text"
                                    value={editedFields[index]?.location || date.location}
                                    onChange={(e) => handleEditChange(index, 'location', e.target.value)}
                                    size="small"
                                    style = {{width: '100%'}}
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Man Amount:
                                </Typography>
                                <TextField
                                    type="text"
                                    value={editedFields[index]?.manAmount || date.manAmount}
                                    onChange={(e) => handleEditChange(index, 'manAmount', e.target.value)}
                                    size="small"
                                    style = {{width: '100%'}}
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Woman Amount:
                                </Typography>
                                <TextField
                                    type="text"
                                    value={editedFields[index]?.womanAmount || date.womanAmount}
                                    onChange={(e) => handleEditChange(index, 'womanAmount', e.target.value)}
                                    size="small"
                                    style = {{width: '100%'}}
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Time:
                                </Typography>
                                <TextField
                                    type="text"
                                    value={editedFields[index]?.time || date.time}
                                    onChange={(e) => handleEditChange(index, 'time', e.target.value)}
                                    size="small"
                                    style = {{width: '100%'}}
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                    Description:
                                </Typography>
                                <TextField
                                    type="text"
                                    value={editedFields[index]?.description || date.description}
                                    onChange={(e) => handleEditChange(index, 'description', e.target.value)}
                                    size="small"
                                    style = {{width: '100%'}}
                                    multiline
                                    rows={null}
                                />
                            </Stack>
                        </Stack>

                        <Stack direction="row" spacing={2} p={2}>
                            <Button onClick={() => handleEdit(date.id)} variant="contained">Save</Button>
                            <Button onClick={() => deleteDate(date.id)} variant="outlined">Delete</Button>
                        </Stack>
                    </Card>
                ))}
            </Stack>
        </Container>
    );
}

export default AdminPage;