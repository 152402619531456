import React from 'react';
import './DatePage.css';
import {useParams} from "react-router-dom";
import {useState,useEffect} from "react";
import { doc, getDoc } from "firebase/firestore/lite";
import db from "../../firebase";

function DatePage(props) {
    const { dateId } = useParams(); // Extract the dateId from URL parameters
    const [dateInfo, setDateInfo] = useState(null);

    useEffect(() => {
        const fetchDateInfo = async () => {
            try {
                const dateDoc = await getDoc(doc(db, "dates", dateId)); // Fetch the date document based on dateId
                if (dateDoc.exists()) {
                    setDateInfo({ id: dateDoc.id, ...dateDoc.data() }); // Set the date info state
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching date info:", error);
            }
        };

        fetchDateInfo();
    }, [dateId]); // Trigger useEffect when dateId changes
    return (
        <div>
            <div>
                {dateInfo ? (
                    <div>
                        <p>Date: {dateInfo.date}</p>
                        <p>Location: {dateInfo.location}</p>
                        <p>Man Amount: {dateInfo.manAmount}</p>
                        <p>Woman Amount: {dateInfo.womanAmount}</p>
                        <p>Time: {dateInfo.time}</p>
                        <p>Description: {dateInfo.description}</p>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
}

export default DatePage;