// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDQCIuzbmZzi9DpGyZJOCfWrQ0eAzeV8_s",
    authDomain: "speeddating-1dc2a.firebaseapp.com",
    projectId: "speeddating-1dc2a",
    storageBucket: "speeddating-1dc2a.appspot.com",
    messagingSenderId: "567545430715",
    appId: "1:567545430715:web:5fb19e24f2b2f30ee79f9e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


// getCities(db)
export default db;